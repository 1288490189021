﻿$(document).ready(function () {
  /*----------------------Function for common on Traingingoffering & Find course & p_home-----------------------------------*/
  $("#btnSearch").click(clickedSearchButton);
  AccodianCustomSetting();

  /* ---------- Acivate Functions ---------- */
  template_functions();
  init_masonry();
  charts();
  calendars();
  growlLikeNotifications();
  circle_progess();
  messageLike();
});

function clickedSearchButton() {
  $.ajax({
    url: $(this).data('request-url'),
    data: { Name: $("#Name").val(), Price: $("#Price").val(), OrganizationId: $('#SelectedorganizationId').val(), IsCourse: $("input[name='isCourse']:checked").val() },
    success: function (data) {
      $("#divResults").empty().html(data);
      AccodianCustomSetting();
    }
  });
}

/* ---------- Like/Dislike ---------- */

function messageLike() {

  if ($('.messagesList')) {

    $('.messagesList').on('click', '.star', function () {

      $(this).removeClass('star');

      $(this).addClass('dislikes');

      //here add your function

    });

    $('.messagesList').on('click', '.dislikes', function () {

      $(this).removeClass('dislikes');

      $(this).addClass('star');

      //here add your function

    });

  }

}

/* ---------- Temp Stats ---------- */

function tempStats() {

  if ($('.tempStat')) {

    $('.tempStat').each(function () {

      var temp = Math.floor(Math.random() * (1 + 120));

      $(this).html(temp + '°');

      if (temp < 20) {

        $(this).animate({
          borderColor: "#67c2ef"
        }, 'fast');

      } else if (temp > 19 && temp < 40) {

        $(this).animate({
          borderColor: "#CBE968"
        }, 'slow');

      } else if (temp > 39 && temp < 60) {

        $(this).animate({
          borderColor: "#eae874"
        }, 'slow');

      } else if (temp > 59 && temp < 80) {

        $(this).animate({
          borderColor: "#fabb3d"
        }, 'slow');

      } else if (temp > 79 && temp < 100) {

        $(this).animate({
          borderColor: "#fa603d"
        }, 'slow');

      } else {

        $(this).animate({
          borderColor: "#ff5454"
        }, 'slow');

      }

    });

  }

}

/* ---------- Masonry Gallery ---------- */

function init_masonry() {
  var $container = $('.masonry-gallery');

  var gutter = 6;
  var min_width = 250;
  $container.imagesLoaded(function () {
    $container.masonry({
      itemSelector: '.masonry-thumb',
      gutterWidth: gutter,
      isAnimated: true,
      columnWidth: function (containerWidth) {
        var num_of_boxes = (containerWidth / min_width | 0);

        var box_width = (((containerWidth - (num_of_boxes - 1) * gutter) / num_of_boxes) | 0);

        if (containerWidth < min_width) {
          box_width = containerWidth;
        }

        $('.masonry-thumb').width(box_width);

        return box_width;
      }
    });
  });
}

/* ---------- Numbers Sepparator ---------- */

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
    x = x.replace(pattern, "$1.$2");
  return x;
}

/* ---------- Template Functions ---------- */

function template_functions() {

  if ($('#g1').length) {

    g1 = new JustGage({ id: "g1", value: 67, min: 0, max: 100, title: "Visitors", label: "per minute" });
    g1a = new JustGage({ id: "g1a", value: 45, min: 0, max: 100, title: "Errors", label: "average" });
    g2 = new JustGage({ id: "g2", value: 15, min: 0, max: 100, title: "Timers", label: "" });
    g2a = new JustGage({ id: "g2a", value: 7, min: 0, max: 100, title: "Alerts", label: "" });
    g2b = new JustGage({ id: "g2b", value: 22, min: 0, max: 100, title: "Events", label: "" });

    setInterval(function () {
      g1.refresh(getRandomInt(50, 100));
      g1a.refresh(getRandomInt(50, 100));
      g2.refresh(getRandomInt(0, 50));
      g2a.refresh(getRandomInt(0, 50));
      g2b.refresh(getRandomInt(0, 50))
    }, 2000
		);

  } else {
    //do nothing
  }

  /* ---------- Wizard ---------- */
  //$('#myWizard').wizard();

  /* ---------- Disable moving to top ---------- */
  $('a[href="#"][data-top!=true]').click(function (e) {
    e.preventDefault();
  });

  /* ---------- Text editor ---------- */



  try {
    $('.cleditor').cleditor();
  }
  catch (e) {

  }



  /* ---------- Datepicker ---------- */



  try {
    $('.datepicker').datepicker();
  }
  catch (e) {

  }
  /* ---------- Notifications ---------- */
  $('.noty').click(function (e) {
    e.preventDefault();
    var options = $.parseJSON($(this).attr('data-noty-options'));
    noty(options);
  });

  /* ---------- Uniform ---------- */

  try {
    $("input:checkbox, input:radio, input:file").not('[data-no-uniform="true"],#uniform-is-ajax').uniform();
  }
  catch (e) {

  }
  /* ---------- Choosen ---------- */

  try {
    $('[data-rel="chosen"],[rel="chosen"]').chosen();
  }
  catch (e) {

  }
  /* ---------- Tabs ---------- */

  try {
    $('#myTab a:first').tab('show');
    $('#myTab a').click(function (e) {
      e.preventDefault();
      $(this).tab('show');
    });
  }
  catch (e) {

  }



  /* ---------- Makes elements soratble, elements that sort need to have id attribute to save the result ---------- */
  try {
    $('.sortable').sortable({
      revert: true,
      cancel: '.btn,.box-content,.nav-header',
      update: function (event, ui) {
        //line below gives the ids of elements, you can make ajax call here to save it to the database
        //console.log($(this).sortable('toArray'));
      }
    });
  }
  catch (e) {

  }


  /* ---------- Tooltip ---------- */
  $('[rel="tooltip"],[data-rel="tooltip"]').tooltip({ "placement": "bottom", delay: { show: 400, hide: 200 } });

  /* ---------- Popover ---------- */
  $('[rel="popover"],[data-rel="popover"]').popover();



    /* ---------- Star Rating ---------- */
    //commented 7/24 causing issue in IE - i dont thsink thsdi is used anywhere...

  //$('.raty').raty({
  //  score: 4 //default stars
  //});

  /* ---------- Uploadify ---------- */
  //$('#file_upload').uploadify({
  //  'swf': 'misc/uploadify.swf',
  //  'uploader': 'misc/uploadify.php'
  //  // Put your options here
  //});

  /* ---------- Fullscreen ---------- */
  $('#toggle-fullscreen').button().click(function () {
    var button = $(this), root = document.documentElement;
    if (!button.hasClass('active')) {
      $('#thumbnails').addClass('modal-fullscreen');
      if (root.webkitRequestFullScreen) {
        root.webkitRequestFullScreen(
					window.Element.ALLOW_KEYBOARD_INPUT
				);
      } else if (root.mozRequestFullScreen) {
        root.mozRequestFullScreen();
      }
    } else {
      $('#thumbnails').removeClass('modal-fullscreen');
      (document.webkitCancelFullScreen ||
				document.mozCancelFullScreen ||
				$.noop).apply(document);
    }
  });

  /* ---------- Datable ---------- */
  $('.datatable').dataTable({
    "sDom": "<'row'<'col-lg-6'l><'col-lg-6'f>r>t<'row'<'col-lg-12'i><'col-lg-12 center'p>>",
    "sPaginationType": "bootstrap",
    "oLanguage": {
      "sLengthMenu": "_MENU_ records per page"
    }
  });

  $('.btn-close').click(function (e) {
    e.preventDefault();
    $(this).parent().parent().parent().fadeOut();
  });
  $('.btn-minimize').click(function (e) {
    e.preventDefault();
    var $target = $(this).parent().parent().next('.box-content');
    if ($target.is(':visible')) $('i', $(this)).removeClass('icon-chevron-up').addClass('icon-chevron-down');
    else $('i', $(this)).removeClass('icon-chevron-down').addClass('icon-chevron-up');
    $target.slideToggle();
  });
  $('.btn-setting').click(function (e) {
    e.preventDefault();
    $('#myModal').modal('show');
  });


  /* ---------- Progress  ---------- */

  $(".simpleProgress").progressbar({
    value: 89
  });

  $(".progressAnimate").progressbar({
    value: 1,
    create: function () {
      $(".progressAnimate .ui-progressbar-value").animate({ "width": "100%" }, {
        duration: 10000,
        step: function (now) {
          $(".progressAnimateValue").html(parseInt(now) + "%");
        },
        easing: "linear"
      })
    }
  });

  $(".progressUploadAnimate").progressbar({
    value: 1,
    create: function () {
      $(".progressUploadAnimate .ui-progressbar-value").animate({ "width": "100%" }, {
        duration: 20000,
        easing: 'linear',
        step: function (now) {
          $(".progressUploadAnimateValue").html(parseInt(now * 40.96) + " Gb");
        },
        complete: function () {
          $(".progressUploadAnimate + .field_notice").html("<span class='must'>Upload Finished</span>");
        }
      })
    }
  });

  if ($(".taskProgress")) {

    $(".taskProgress").each(function () {

      var endValue = parseInt($(this).html());

      $(this).progressbar({
        value: endValue
      });

      $(this).parent().find(".percent").html(endValue + "%");

    });

  }

  if ($(".progressBarValue")) {

    $(".progressBarValue").each(function () {

      var endValueHTML = $(this).find(".progressCustomValueVal").html();

      var endValue = parseInt(endValueHTML);

      $(this).find(".progressCustomValue").progressbar({

        value: 1,
        create: function () {
          $(this).find(".ui-progressbar-value").animate({ "width": endValue + "%" }, {
            duration: 5000,
            step: function (now) {

              $(this).parent().parent().parent().find(".progressCustomValueVal").html(parseInt(now) + "%");
            },
            easing: "linear"
          })
        }
      });

    });

  }


  /* ---------- Custom Slider ---------- */
  $(".sliderSimple").slider();

  $(".sliderMin").slider({
    range: "min",
    value: 180,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderMinLabel").html("$" + ui.value);
    }
  });

  $(".sliderMin-1").slider({
    range: "min",
    value: 50,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderMin1Label").html("$" + ui.value);
    }
  });

  $(".sliderMin-2").slider({
    range: "min",
    value: 100,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderMin2Label").html("$" + ui.value);
    }
  });

  $(".sliderMin-3").slider({
    range: "min",
    value: 150,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderMin3Label").html("$" + ui.value);
    }
  });

  $(".sliderMin-4").slider({
    range: "min",
    value: 250,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderMin4Label").html("$" + ui.value);
    }
  });

  $(".sliderMin-5").slider({
    range: "min",
    value: 350,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderLabel").html("$" + ui.value);
    }
  });

  $(".sliderMin-6").slider({
    range: "min",
    value: 450,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderLabel").html("$" + ui.value);
    }
  });

  $(".sliderMin-7").slider({
    range: "min",
    value: 550,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderLabel").html("$" + ui.value);
    }
  });

  $(".sliderMin-8").slider({
    range: "min",
    value: 650,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderLabel").html("$" + ui.value);
    }
  });


  $(".sliderMax").slider({
    range: "max",
    value: 280,
    min: 1,
    max: 700,
    slide: function (event, ui) {
      $(".sliderMaxLabel").html("$" + ui.value);
    }
  });

  $(".sliderRange").slider({
    range: true,
    min: 0,
    max: 500,
    values: [192, 470],
    slide: function (event, ui) {
      $(".sliderRangeLabel").html("$" + ui.values[0] + " - $" + ui.values[1]);
    }
  });

  $("#sliderVertical-1").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 60,
  });

  $("#sliderVertical-2").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 40,
  });

  $("#sliderVertical-3").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 30,
  });

  $("#sliderVertical-4").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 15,
  });

  $("#sliderVertical-5").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 40,
  });

  $("#sliderVertical-6").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 80,
  });

  $("#sliderVertical-7").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 60,
  });

  $("#sliderVertical-8").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 40,
  });

  $("#sliderVertical-9").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 30,
  });

  $("#sliderVertical-10").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 15,
  });

  $("#sliderVertical-11").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 40,
  });

  $("#sliderVertical-12").slider({
    orientation: "vertical",
    range: "min",
    min: 0,
    max: 100,
    value: 80,
  });

}

/* ---------- Circle Progess Bars ---------- */
function circle_progess() {

  var divElement = $('div'); //log all div elements

  if (retina()) {

    $(".whiteCircle").knob({
      'min': 0,
      'max': 100,
      'readOnly': true,
      'width': 240,
      'height': 240,
      'bgColor': 'rgba(255,255,255,0.5)',
      'fgColor': 'rgba(255,255,255,0.9)',
      'dynamicDraw': true,
      'thickness': 0.2,
      'tickColorizeValues': true
    });

    $(".circleStat").css('zoom', 0.5);
    $(".whiteCircle").css('zoom', 0.999);

  } else {

    $(".whiteCircle").knob({
      'min': 0,
      'max': 100,
      'readOnly': true,
      'width': 120,
      'height': 120,
      'bgColor': 'rgba(255,255,255,0.5)',
      'fgColor': 'rgba(255,255,255,0.9)',
      'dynamicDraw': true,
      'thickness': 0.2,
      'tickColorizeValues': true
    });

  }

  $(".circleStatsItemBox").each(function () {

    var value = $(this).find(".value > .number").html();
    var unit = $(this).find(".value > .unit").html();
    var percent = $(this).find("input").val() / 100;

    countSpeed = 2300 * percent;

    endValue = value * percent;

    $(this).find(".count > .unit").html(unit);
    $(this).find(".count > .number").countTo({

      from: 0,
      to: endValue,
      speed: countSpeed,
      refreshInterval: 50,
      onComplete: function (value) {
        console.debug(this);
      }

    });

  });

  $('.circleChart').each(function () {

    var circleColor = $(this).parent().css('color');

    $(this).knob({
      'min': 0,
      'max': 100,
      'readOnly': true,
      'width': 120,
      'height': 120,
      'fgColor': circleColor,
      'dynamicDraw': true,
      'thickness': 0.2,
      'tickColorizeValues': true,
      'skin': 'tron'
    });

  });

  $('.knob').each(function () {

    $(this).knob();

  });

}

/* ---------- Calendars ---------- */

function calendars() {


  $('#external-events div.external-event').each(function () {

    // it doesn't need to have a start or end
    var eventObject = {
      title: $.trim($(this).text()) // use the element's text as the event title
    };

    // store the Event Object in the DOM element so we can get to it later
    $(this).data('eventObject', eventObject);

    // make the event draggable using jQuery UI
    $(this).draggable({
      zIndex: 999,
      revert: true,      // will cause the event to go back to its
      revertDuration: 0  //  original position after the drag
    });

  });

  var date = new Date();
  var d = date.getDate();
  var m = date.getMonth();
  var y = date.getFullYear();

  $('#main_calendar').fullCalendar({
    header: {
      left: 'title',
      right: 'prev,next today,month,agendaWeek,agendaDay'
    },
    editable: true,
    events: [
			{
			  title: 'All Day Event',
			  start: new Date(y, m, 1)
			},
			{
			  title: 'Long Event',
			  start: new Date(y, m, d - 5),
			  end: new Date(y, m, d - 2)
			},
			{
			  id: 999,
			  title: 'Repeating Event',
			  start: new Date(y, m, d - 3, 16, 0),
			  allDay: false
			},
			{
			  id: 999,
			  title: 'Repeating Event',
			  start: new Date(y, m, d + 4, 16, 0),
			  allDay: false
			},
			{
			  title: 'Meeting',
			  start: new Date(y, m, d, 10, 30),
			  allDay: false
			},
			{
			  title: 'Lunch',
			  start: new Date(y, m, d, 12, 0),
			  end: new Date(y, m, d, 14, 0),
			  allDay: false
			},
			{
			  title: 'Birthday Party',
			  start: new Date(y, m, d + 1, 19, 0),
			  end: new Date(y, m, d + 1, 22, 30),
			  allDay: false
			},
			{
			  title: 'Click for Google',
			  start: new Date(y, m, 28),
			  end: new Date(y, m, 29),
			  url: 'http://google.com/'
			}
    ]
  });

  $('.calendar-small').fullCalendar({
    header: {
      right: 'title',
      left: 'prev,next,today'
    },
    defaultView: 'month',
    editable: true,
    events: [
			{
			  title: 'All Day Event',
			  start: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 17),
			  allDay: true
			},
			{
			  title: 'Long Event',
			  start: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 15, 11, 30, 0),
			  end: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 10, 12, 30, 0)
			},
			{
			  id: 999,
			  title: 'Repeating Event',
			  start: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 9, 8, 0, 0),
			  end: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 9, 10, 0, 0),
			  allDay: false
			},
			{
			  id: 999,
			  title: 'Repeating Event',
			  start: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 2, 8, 0, 0),
			  end: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 2, 10, 0, 0),
			  allDay: false
			},
			{
			  title: 'Meeting',
			  start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30, 0),
			  end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 30, 0),
			  allDay: false
			},
			{
			  title: 'Lunch',
			  start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14, 0, 0),
			  end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 15, 30, 0),
			  allDay: false
			},
			{
			  title: 'Birthday Party',
			  start: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 19, 30, 0),
			  end: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 22, 30, 0),
			  allDay: false
			},
			{
			  title: 'Click for Google',
			  start: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 10, 14, 30, 0),
			  end: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 11, 12, 30, 0),
			  url: 'http://google.com/'
			}
    ],
    dayClick: function (date, allDay, jsEvent, view) {

      $('.calendar-details > .events').html('');

      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      var month = new Array();
      month[0] = "January";
      month[1] = "February";
      month[2] = "March";
      month[3] = "April";
      month[4] = "May";
      month[5] = "June";
      month[6] = "July";
      month[7] = "August";
      month[8] = "September";
      month[9] = "October";
      month[10] = "November";
      month[11] = "December";

      var date2 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      var todaysEvents = $('.calendar-small').fullCalendar('clientEvents', function (event) {

        function pad(n) { return n < 10 ? '0' + n : n }

        if (event.start >= date && event.start < date2) {

          var title = event.title;
          var start = event.start;
          var end = event.end;

          $('.calendar-details > .day').html(weekday[date.getDay()]);
          $('.calendar-details > .date').html(month[date.getMonth()] + ' ' + date.getDate());

          if (event.allDay) {

            $('.calendar-details > .events').append(
							'<li>'
							+ title
							+ ' - all day'
							+ '</li>');

          } else {

            $('.calendar-details > .events').append(
							'<li>'
							+ title
							+ ' - '
							+ start.getHours()
							+ ':'
							+ pad(start.getMinutes())
							+ ' - '
							+ end.getHours()
							+ ':'
							+ pad(end.getMinutes())
							+ '</li>');

          }

        } else if (date >= event.start && date <= event.end) {

          var title = event.title;
          var start = event.start;
          var end = event.end;

          $('.calendar-details > .day').html(weekday[date.getDay()]);
          $('.calendar-details > .date').html(month[date.getMonth()] + ' ' + date.getDate());
          $('.calendar-details > .events').append(
						'<li>'
						+ title
						+ ' - '
						+ month[start.getMonth()] + ' ' + start.getDate()
						+ ' '
						+ start.getHours()
						+ ':'
						+ pad(start.getMinutes())
						+ ' - '
						+ month[end.getMonth()] + ' ' + end.getDate()
						+ ' '
						+ end.getHours()
						+ ':'
						+ pad(end.getMinutes())
						+ '</li>');

        } else {

          $('.calendar-details > .day').html(weekday[date.getDay()]);
          $('.calendar-details > .date').html(month[date.getMonth()] + ' ' + date.getDate());

        }

      });

      //count li elements

      if ($('.calendar-details ul li').length == 0) {
        $('.calendar-details > .events').html('<li>no events :)</li>');
      }

    }
  });


  $('#main_calendar_phone').fullCalendar({
    header: {
      left: 'title',
      right: 'prev,next'
    },
    defaultView: 'agendaDay',
    editable: true,
    events: [
			{
			  title: 'All Day Event',
			  start: new Date(y, m, 1)
			},
			{
			  title: 'Long Event',
			  start: new Date(y, m, d - 5),
			  end: new Date(y, m, d - 2)
			},
			{
			  id: 999,
			  title: 'Repeating Event',
			  start: new Date(y, m, d - 3, 16, 0),
			  allDay: false
			},
			{
			  id: 999,
			  title: 'Repeating Event',
			  start: new Date(y, m, d + 4, 16, 0),
			  allDay: false
			},
			{
			  title: 'Meeting',
			  start: new Date(y, m, d, 10, 30),
			  allDay: false
			},
			{
			  title: 'Lunch',
			  start: new Date(y, m, d, 12, 0),
			  end: new Date(y, m, d, 14, 0),
			  allDay: false
			},
			{
			  title: 'Birthday Party',
			  start: new Date(y, m, d + 1, 19, 0),
			  end: new Date(y, m, d + 1, 22, 30),
			  allDay: false
			},
			{
			  title: 'Click for Google',
			  start: new Date(y, m, 28),
			  end: new Date(y, m, 29),
			  url: 'http://google.com/'
			}
    ]
  });


  $('#calendar').fullCalendar({
    header: {
      left: 'title',
      right: 'prev,next today,month,agendaWeek,agendaDay'
    },
    editable: true,
    droppable: true, // this allows things to be dropped onto the calendar !!!
    drop: function (date, allDay) { // this function is called when something is dropped

      // retrieve the dropped element's stored Event Object
      var originalEventObject = $(this).data('eventObject');

      // we need to copy it, so that multiple events don't have a reference to the same object
      var copiedEventObject = $.extend({}, originalEventObject);

      // assign it the date that was reported
      copiedEventObject.start = date;
      copiedEventObject.allDay = allDay;

      // render the event on the calendar
      // the last `true` argument determines if the event "sticks" (http://arshaw.com/fullcalendar/docs/event_rendering/renderEvent/)
      $('#calendar').fullCalendar('renderEvent', copiedEventObject, true);

      // is the "remove after drop" checkbox checked?
      if ($('#drop-remove').is(':checked')) {
        // if so, remove the element from the "Draggable Events" list
        $(this).remove();
      }

    }
  });

}

/* ---------- Charts ---------- */


function growlLikeNotifications() {

  $('#add-sticky').click(function () {

    var unique_id = $.gritter.add({
      // (string | mandatory) the heading of the notification
      title: 'This is a sticky notice!',
      // (string | mandatory) the text inside the notification
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget tincidunt velit. Cum sociis natoque penatibus et <a href="#" style="color:#ccc">magnis dis parturient</a> montes, nascetur ridiculus mus.',
      // (string | optional) the image to display on the left
      image: '../img/avatar.jpg',
      // (bool | optional) if you want it to fade out on its own or just sit there
      sticky: true,
      // (int | optional) the time you want it to be alive for before fading out
      time: '',
      // (string | optional) the class name you want to apply to that specific message
      class_name: 'my-sticky-class'
    });

    // You can have it return a unique id, this can be used to manually remove it later using
    /* ----------
		setTimeout(function(){

			$.gritter.remove(unique_id, {
				fade: true,
				speed: 'slow'
			});

		}, 6000)
		*/

    return false;

  });

  $('#add-regular').click(function () {

    $.gritter.add({
      // (string | mandatory) the heading of the notification
      title: 'This is a regular notice!',
      // (string | mandatory) the text inside the notification
      text: 'This will fade out after a certain amount of time. Vivamus eget tincidunt velit. Cum sociis natoque penatibus et <a href="#" style="color:#ccc">magnis dis parturient</a> montes, nascetur ridiculus mus.',
      // (string | optional) the image to display on the left
      image: '../img/avatar.jpg',
      // (bool | optional) if you want it to fade out on its own or just sit there
      sticky: false,
      // (int | optional) the time you want it to be alive for before fading out
      time: ''
    });

    return false;

  });

  $('#add-max').click(function () {

    $.gritter.add({
      // (string | mandatory) the heading of the notification
      title: 'This is a notice with a max of 3 on screen at one time!',
      // (string | mandatory) the text inside the notification
      text: 'This will fade out after a certain amount of time. Vivamus eget tincidunt velit. Cum sociis natoque penatibus et <a href="#" style="color:#ccc">magnis dis parturient</a> montes, nascetur ridiculus mus.',
      // (string | optional) the image to display on the left
      image: '../img/avatar.jpg',
      // (bool | optional) if you want it to fade out on its own or just sit there
      sticky: false,
      // (function) before the gritter notice is opened
      before_open: function () {
        if ($('.gritter-item-wrapper').length == 3) {
          // Returning false prevents a new gritter from opening
          return false;
        }
      }
    });

    return false;

  });

  $('#add-without-image').click(function () {

    $.gritter.add({
      // (string | mandatory) the heading of the notification
      title: 'This is a notice without an image!',
      // (string | mandatory) the text inside the notification
      text: 'This will fade out after a certain amount of time. Vivamus eget tincidunt velit. Cum sociis natoque penatibus et <a href="#" style="color:#ccc">magnis dis parturient</a> montes, nascetur ridiculus mus.'
    });

    return false;
  });

  $('#add-gritter-light').click(function () {

    $.gritter.add({
      // (string | mandatory) the heading of the notification
      title: 'This is a light notification',
      // (string | mandatory) the text inside the notification
      text: 'Just add a "gritter-light" class_name to your $.gritter.add or globally to $.gritter.options.class_name',
      class_name: 'gritter-light'
    });

    return false;
  });

  $('#add-with-callbacks').click(function () {

    $.gritter.add({
      // (string | mandatory) the heading of the notification
      title: 'This is a notice with callbacks!',
      // (string | mandatory) the text inside the notification
      text: 'The callback is...',
      // (function | optional) function called before it opens
      before_open: function () {
        alert('I am called before it opens');
      },
      // (function | optional) function called after it opens
      after_open: function (e) {
        alert("I am called after it opens: \nI am passed the jQuery object for the created Gritter element...\n" + e);
      },
      // (function | optional) function called before it closes
      before_close: function (e, manual_close) {
        var manually = (manual_close) ? 'The "X" was clicked to close me!' : '';
        alert("I am called before it closes: I am passed the jQuery object for the Gritter element... \n" + manually);
      },
      // (function | optional) function called after it closes
      after_close: function (e, manual_close) {
        var manually = (manual_close) ? 'The "X" was clicked to close me!' : '';
        alert('I am called after it closes. ' + manually);
      }
    });

    return false;
  });

  $('#add-sticky-with-callbacks').click(function () {

    $.gritter.add({
      // (string | mandatory) the heading of the notification
      title: 'This is a sticky notice with callbacks!',
      // (string | mandatory) the text inside the notification
      text: 'Sticky sticky notice.. sticky sticky notice...',
      // Stickeh!
      sticky: true,
      // (function | optional) function called before it opens
      before_open: function () {
        alert('I am a sticky called before it opens');
      },
      // (function | optional) function called after it opens
      after_open: function (e) {
        alert("I am a sticky called after it opens: \nI am passed the jQuery object for the created Gritter element...\n" + e);
      },
      // (function | optional) function called before it closes
      before_close: function (e) {
        alert("I am a sticky called before it closes: I am passed the jQuery object for the Gritter element... \n" + e);
      },
      // (function | optional) function called after it closes
      after_close: function () {
        alert('I am a sticky called after it closes');
      }
    });

    return false;

  });

  $("#remove-all").click(function () {

    $.gritter.removeAll();
    return false;

  });

  $("#remove-all-with-callbacks").click(function () {

    $.gritter.removeAll({
      before_close: function (e) {
        alert("I am called before all notifications are closed.  I am passed the jQuery object containing all  of Gritter notifications.\n" + e);
      },
      after_close: function () {
        alert('I am called after everything has been closed.');
      }
    });
    return false;

  });


}

/* ---------- Page width functions ---------- */

$(window).bind("resize", widthFunctions);

function widthFunctions(e) {

  if ($('.timeline')) {

    $('.timeslot').each(function () {

      var timeslotHeight = $(this).find('.task').outerHeight();

      $(this).css('height', timeslotHeight);

    });

  }

  var sidebarLeftHeight = $('#sidebar-left').outerHeight();
  var contentHeight = $('#content').height();
  var contentHeightOuter = $('#content').outerHeight();

  var winHeight = $(window).height();
  var winWidth = $(window).width();

  if (winWidth > 767) {

    if (sidebarLeftHeight > contentHeight) {

      $('#content').css("height", sidebarLeftHeight);

    } else {

      $('#content').css("height", "auto");

    }

    $('#white-area').css('height', contentHeightOuter);

  } else {

    $('#white-area').css('height', 'auto');

  }


  if (winWidth < 768) {

    if ($('.chat-full')) {

      $('.chat-full').each(function () {

        $(this).addClass('alt');

      });

    }

  } else {

    if ($('.chat-full')) {

      $('.chat-full').each(function () {

        $(this).removeClass('alt');

      });

    }

  }


}





/*------------------common function for accrodian open on staring and user can not close accordain-----------------------------*/
//var isallopen = false;
function AccodianCustomSetting() {
  console.log(console.trace());
  console.log("custom accordion settings");
  if ($('#CourseEventlist').length) {
    //added for ie error on some pages because this function is called when object doesent exist

    $("#CourseEventlist").accordion({
      heightStyle: "content",
      collapsible: false,
      active: false,
      icons: null,
      beforeActivate: function (event, ui) {
        //  console.log(114);
        // The accordion believes a panel is being opened
        if (ui.newHeader[0]) {
          var currHeader = ui.newHeader;
          var currContent = currHeader.next('.ui-accordion-content');
          // The accordion believes a panel is being closed
          //console.log(112);
        } else {
          var currHeader = ui.oldHeader;
          var currContent = currHeader.next('.ui-accordion-content');
          // console.log(113);
        }
        // Since we've changed the default behavior, this detects the actual status
        var isPanelSelected = currHeader.attr('aria-selected') == 'true';

        // Toggle the panel's header
        currHeader.toggleClass('ui-corner-all', isPanelSelected).toggleClass('accordion-header-active ui-state-active ui-corner-top', !isPanelSelected).attr('aria-selected', ((!isPanelSelected).toString()));

        // Toggle the panel's icon
        currHeader.children('.ui-icon').toggleClass('ui-icon-triangle-1-e', isPanelSelected).toggleClass('ui-icon-triangle-1-s', !isPanelSelected);

        // Toggle the panel's content
        currContent.toggleClass('accordion-content-active', !isPanelSelected)
        if (isPanelSelected) { currContent.slideUp(); } else { currContent.slideDown(); }


        return false; // Cancels the default action

      }
    });
    for (var id = 0; id < $('#CourseEventlist h3').size() ; id++) {
      $("#CourseEventlist").accordion({ active: id });
    }
    $('#CourseEventlist .titlepadding1').click(function (event) {

      event.stopImmediatePropagation();
    });
    //$('.titlepadding').click(function (event) {
    //   // console.log('13');
    //    event.stopImmediatePropagation();
    //});
  }
}

function Accordainheader(obj) {
  //console.log('1');
  //obj.stopImmediatePropagation();


}


