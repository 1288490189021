﻿$(init);

function init() {
  $('#global_unimpersonate').click(clickedUnimpersonate);
  $('#global_about_link').click(clickedAbout);
  $('.scrollToLocation').click(scrollToLocationClicked);
}

function clickedUnimpersonate() {
  jQuery.ajax({
    type: "POST",
    contentType: "application/json; charset=utf-8",
    url: '/PageTool/ImpersonateRemoveUser',
    data: JSON.stringify({ UserId: user.id }),
    dataType: "json",
    async: true,
    success: function (data, textStatus) {
      setTimeout(function () {
        window.location.href = '/Account/SelectRole';
      }, 200);
    },
    error: function (data, status, error) {
    }
  });
}

function clickedAbout(event) {
  if (1 + window.location.indexOf($(this).attr('href'))) {
    event.preventDefault();
  }
};

function scrollToLocationClicked(event) {
  var target = $($(this).attr('href'));
  if (target.length) return; //no target in page, let click fallthrough

  event.preventDefault(); //don't transition to the link
  var position = target.offset();
  $('html, body').animate({ scrollTop: position.top }, "slow");
}